import { TabsFunction } from "../TabsFunction";
import { useColorMode, Box, Image, Text, SimpleGrid, Center, Menu, MenuButton, MenuList, MenuItem, Button} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import skillsData from "../../../data/SkillsData";

export const Skills = () => {
    const colorMode = useColorMode()
    const [ viemPath, setViemPath] = useState('')
    const [ owncloudPath, setOwncloudPath] = useState('')
    const [selectedTab, setSelectedTab] = useState("Network / System");
    const [buttonSkills, setButtonSkills] = useState("Skills");

    const handleKeyDown = (event) => {
        const currentIndex = tabs.findIndex(tab => tab.label === selectedTab);
        let newIndex;
    
        if (event.key === 'ArrowRight') {
            newIndex = (currentIndex + 1) % tabs.length;
        } else if (event.key === 'ArrowLeft') {
            newIndex = (currentIndex - 1 + tabs.length) % tabs.length;
        } else {
            return; // Si une autre touche est pressée, ne faites rien
        }
    
        setSelectedTab(tabs[newIndex].label);
    };    

    useEffect(() => {
        if (colorMode.colorMode === 'dark') {
            setViemPath('./images/logo/viem-white.png')
            setOwncloudPath('./images/logo/owncloud-white.png')
        } else {
            setViemPath('./images/logo/viem-black.png')
            setOwncloudPath('./images/logo/owncloud-black.png')
        }
    }, [colorMode])

    const renderTabContent = (content) => {
        return (
            <SimpleGrid zIndex={3} mt='50px' columns={[3, null, 5]} gap={6}>
                {content.map((item, index) => (
                    <Box key={index} display="flex" flexDirection="column" alignItems="center">
                        <a className="skillsbutton" href={item.link} target="_blank" rel="noopener noreferrer">
                            <Image style={{width: "100px", height: "100px", objectFit: "contain"}}
                                    borderRadius={item.imgTitle === 'Matic' || item.imgTitle === 'Aave' ? '50%' : ''}
                                    src={item.imgTitle === 'viem' ? viemPath : item.imgTitle === 'owncloud' ? owncloudPath : item.imgSrc}
                                    alt={item.imgAlt} 
                                    title={item.imgTitle} />
                        </a>
                        <Text>{item.text}</Text>
                    </Box>
                ))}
            </SimpleGrid>
        );
    };

    
    const networkSkills = () => {
        for (let i = 0; i < skillsData.length; i++) {
            if (skillsData[i].label === "Network / System") {
                const content = skillsData[i].content;
                return renderTabContent(content);
            }
        }
    };

    
    const scriptSkills = () => {
        for (let i = 0; i < skillsData.length; i++) {
            if (skillsData[i].label === "Script") {
                const content = skillsData[i].content;
                return renderTabContent(content);
            }
        }
    };

    
    const devFrontEndSkills = () => {
        for (let i = 0; i < skillsData.length; i++) {
            if (skillsData[i].label === "Front-end Web") {
                const content = skillsData[i].content;
                return renderTabContent(content);
            }
        }
    };

    
    const devBackEndSkills = () => {
        for (let i = 0; i < skillsData.length; i++) {
            if (skillsData[i].label === "Back-end Web") {
                const content = skillsData[i].content;
                return renderTabContent(content);
            }
        }
    };

    
    const devBlockchainSkills = () => {
        for (let i = 0; i < skillsData.length; i++) {
            if (skillsData[i].label === "Dev Blockchain") {
                const content = skillsData[i].content;
                return renderTabContent(content);
            }
        }
    };

    
    const blockchainSkills = () => {
        for (let i = 0; i < skillsData.length; i++) {
            if (skillsData[i].label === "Blockchain / DeFi") {
                const content = skillsData[i].content;
                return renderTabContent(content);
            }
        }
    };

    const tabs = [
        {label: "Network / System", content: networkSkills()},
        {label: "Front-end Web", content: devFrontEndSkills()},
        {label: "Back-end Web", content: devBackEndSkills()},
        {label: "Script", content: scriptSkills()},
        {label: "Dev Blockchain", content: devBlockchainSkills()},
        {label: "Blockchain / DeFi", content: blockchainSkills()},
    ]

    return (
        <>
            <Box mt='32px'zIndex={2} display={{ base: 'none', lg: 'block' }} w={['375px', null, '650px', '750px', '1000px', '1250px']} onKeyDown={handleKeyDown} tabIndex={0}>
                <Center>
                    <Text className="font-2" fontSize='26px'>What are my skills ?</Text>
                </Center>

                <TabsFunction tabs={tabs} width={{}} />
            </Box>

            {/* Phone */}
            <Box display={{base: 'block', lg: 'none'}}>

                <Center>
                    <Text className="font-2" fontSize='26px'>What are my skills ?</Text>
                </Center>

                <Menu>
                    <MenuButton mt='12px' as={Button} rightIcon={<ChevronDownIcon />} >{buttonSkills}</MenuButton>

                    <MenuList>
                        {tabs.map((tab, index) => (
                            <MenuItem key={index} onClick={() => {setSelectedTab(tab.label); setButtonSkills(tab.label);}}>
                                {tab.label}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>

                {tabs.map((tab) => (
                    <Box key={tab.label} display={selectedTab === tab.label ? "block" : "none"}>
                        {tab.content}
                    </Box>
                ))}
            </Box>
        </>
    )
}