const skillsData = [{
        label: "Network / System",
        content: [{
                link: "https://www.linux.org/",
                imgSrc: "./images/logo/linux.png",
                imgAlt: "logo linux",
                imgTitle: "linux",
                text: "Linux",
            },
            {
                link: "https://www.debian.org/",
                imgSrc: "./images/logo/debian.png",
                imgAlt: "logo debian",
                imgTitle: "debian",
                text: "Debian",
            },
            {
                link: "https://nginx.org/",
                imgSrc: "./images/logo/nginx.png",
                imgAlt: "logo nginx",
                imgTitle: "nginx webserver",
                text: "Nginx",
            },
            {
                link: "https://httpd.apache.org/",
                imgSrc: "./images/logo/apache-httpd.png",
                imgAlt: "logo apache2",
                imgTitle: "apache2",
                text: "Apache Web Server",
            },
            {
                link: "https://owncloud.com/",
                imgSrc: "./images/logo/owncloud-black.png",
                imgAlt: "logo owncloud",
                imgTitle: "owncloud",
                text: "Owncloud",
            },
            {
                link: "https://www.centreon.com/",
                imgSrc: "./images/logo/centreon.png",
                imgAlt: "logo centreon",
                imgTitle: "centreon monitoring server",
                text: "Centreon",
            },
            {
                link: "https://glpi-project.org/",
                imgSrc: "./images/logo/glpi.png",
                imgAlt: "logo glpi",
                imgTitle: "glpi ticket",
                text: "GLPI",
            },
            {
                link: "https://webmin.com/",
                imgSrc: "./images/logo/webmin.png",
                imgAlt: "logo webmin",
                imgTitle: "webmin",
                text: "Webmin",
            },
            {
                link: "https://www.crowdsec.net/",
                imgSrc: "./images/logo/crowdsec.png",
                imgAlt: "logo crowdsec",
                imgTitle: "crowdsec",
                text: "Crowdsec",
            },
            {
                link: "https://github.com/fail2ban/fail2ban",
                imgSrc: "./images/logo/fail2ban.png",
                imgAlt: "logo fail2ban",
                imgTitle: "fail2ban",
                text: "Fail2ban",
            },
            {
                link: "https://mariadb.org/",
                imgSrc: "./images/logo/mariadb.png",
                imgAlt: "logo mariadb",
                imgTitle: "mariadb",
                text: "MariaDB",
            },
            {
                link: "https://certbot.eff.org/",
                imgSrc: "./images/logo/certbot.png",
                imgAlt: "logo certbot",
                imgTitle: "certbot",
                text: "Certbot",
            },
            {
                link: "https://www.microsoft.com/en-us/windows-server",
                imgSrc: "./images/logo/windows-server-1.png",
                imgAlt: "logo windows server",
                imgTitle: "windows server",
                text: "Windows Server",
            },

        ],
    },
    {
        label: "Front-end Web",
        content: [{
                link: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
                imgSrc: "./images/logo/javascript.jpg",
                imgAlt: "logo javascript",
                imgTitle: "javascript",
                text: "Javascript",
            },
            {
                link: "https://react.dev/",
                imgSrc: "./images/logo/react-logo.png",
                imgAlt: "logo react.js",
                imgTitle: "react.js",
                text: "React.js",
            },
            {
                link: "https://chakra-ui.com/getting-started",
                imgSrc: "./images/logo/chakra-ui.png",
                imgAlt: "logo chakra-ui",
                imgTitle: "chakra-ui",
                text: "Chakra UI",
            },
            {
                link: "https://redux.js.org/",
                imgSrc: "./images/logo/redux.png",
                imgAlt: "logo redux toolkit",
                imgTitle: "redux toolkit",
                text: "Redux toolkit",
            },
            {
                link: "https://www.chartjs.org/",
                imgSrc: "./images/logo/chart-js.png",
                imgAlt: "logo chart.js",
                imgTitle: "chart.js",
                text: "Chart.js",
            },
            {
                link: "https://tailwindcss.com/",
                imgSrc: "./images/logo/tailwind-css.png",
                imgAlt: "logo tailwind css",
                imgTitle: "tailwind css",
                text: "Tailwind CSS",
            },
            {
                link: "https://html.spec.whatwg.org/multipage/",
                imgSrc: "./images/logo/html.png",
                imgAlt: "logo html",
                imgTitle: "html",
                text: "HTML",
            },
            {
                link: "https://www.w3.org/Style/CSS/",
                imgSrc: "./images/logo/css.png",
                imgAlt: "logo css",
                imgTitle: "css",
                text: "CSS",
            },

        ],
    },
    {
        label: "Back-end Web",
        content: [{
                link: "https://www.python.org/",
                imgSrc: "./images/logo/python.png",
                imgAlt: "logo python",
                imgTitle: "python",
                text: "Python",
            },
            {
                link: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
                imgSrc: "./images/logo/javascript.jpg",
                imgAlt: "logo javascript",
                imgTitle: "javascript",
                text: "Javascript",
            },
            {
                link: "https://nodejs.org/en",
                imgSrc: "./images/logo/node-js.png",
                imgAlt: "logo node.js",
                imgTitle: "node.js",
                text: "Node.js",
            },
            {
                link: "https://discord.js.org/",
                imgSrc: "./images/logo/discord-js.png",
                imgAlt: "logo discord-js",
                imgTitle: "discord-js",
                text: "Discord.js",
            },
            {
                link: "https://expressjs.com/",
                imgSrc: "./images/logo/express-js.png",
                imgAlt: "logo express-js",
                imgTitle: "express-js",
                text: "Express",
            },
            {
                link: "https://nodemailer.com/",
                imgSrc: "./images/logo/nodemailer.png",
                imgAlt: "logo nodemailer",
                imgTitle: "nodemailer",
                text: "Nodemailer",
            },
            {
                link: "https://www.mysql.com/",
                imgSrc: "./images/logo/mysql.png",
                imgAlt: "logo mysql",
                imgTitle: "mysql",
                text: "MySQL",
            },

        ],
    },
    {
        label: "Script",
        content: [{
                link: "https://www.python.org/",
                imgSrc: "./images/logo/python.png",
                imgAlt: "logo python",
                imgTitle: "python",
                text: "Python",
            },
            {
                link: "https://opencv.org/",
                imgSrc: "./images/logo/opencv.png",
                imgAlt: "logo opencv",
                imgTitle: "opencv",
                text: "OpenCV",
            },
            {
                link: "https://numpy.org/",
                imgSrc: "./images/logo/numpy.png",
                imgAlt: "logo numpy",
                imgTitle: "numpy",
                text: "Numpy",
            },
            {
                link: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
                imgSrc: "./images/logo/javascript.jpg",
                imgAlt: "logo javascript",
                imgTitle: "javascript",
                text: "Javascript",
            },
            {
                link: "https://nodejs.org/en",
                imgSrc: "./images/logo/node-js.png",
                imgAlt: "logo node.js",
                imgTitle: "node.js",
                text: "Node.js",
            },

        ],
    },
    {
        label: "Dev Blockchain",
        content: [{
                link: "https://docs.web3js.org/",
                imgSrc: "./images/logo/web3js.png",
                imgAlt: "logo web3.js",
                imgTitle: "web3.js",
                text: "Web3.js",
            },
            {
                link: "https://viem.io/",
                imgSrc: "./images/logo/viem-white.png",
                imgAlt: "logo viem",
                imgTitle: "viem",
                text: "Viem",
            },
            {
                link: "https://walletconnect.com/",
                imgSrc: "./images/logo/walletconnect.png",
                imgAlt: "logo walletconnect",
                imgTitle: "walletconnect",
                text: "Wallet Connect",
            },
            {
                link: "https://soliditylang.org/",
                imgSrc: "./images/logo/sol.png",
                imgAlt: "logo solidity",
                imgTitle: "solidity",
                text: "Solidity",
            },
            {
                link: "https://hardhat.org/",
                imgSrc: "./images/logo/hardhat.png",
                imgAlt: "logo hardhat",
                imgTitle: "hardhat",
                text: "Hardhat",
            },

        ],
    },
    {
        label: "Blockchain / DeFi",
        content: [{
                link: "https://bitcoin.org/",
                imgSrc: "./images/logo/bitcoin.png",
                imgAlt: "logo Bitoin",
                imgTitle: "Bitcoin",
                text: "Bitcoin",
            },
            {
                link: "https://www.getmonero.org/",
                imgSrc: "./images/logo/xmr.png",
                imgAlt: "logo Monero",
                imgTitle: "Monero",
                text: "Monero",
            },
            {
                link: "https://ethereum.org/",
                imgSrc: "./images/logo/ethereum.png",
                imgAlt: "logo Ethereum",
                imgTitle: "Ethereum",
                text: "Ethereum",
            },
            {
                link: "https://polygon.technology/",
                imgSrc: "./images/logo/matic.png",
                imgAlt: "logo Matic",
                imgTitle: "Matic",
                text: "Matic",
            },
            {
                link: "https://chain.link/",
                imgSrc: "./images/logo/link.png",
                imgAlt: "logo Chainlink",
                imgTitle: "Chainlink",
                text: "Chainlink",
            },
            {
                link: "https://forlootandglory.io/",
                imgSrc: "./images/logo/flag.png",
                imgAlt: "logo For Loot And Glory",
                imgTitle: "For Loot And Glory",
                text: "FLAG",
            },
            {
                link: "https://kyber.network/",
                imgSrc: "./images/logo/kyber.png",
                imgAlt: "logo Kyber",
                imgTitle: "Kyber",
                text: "KyberSwap",
            },
            {
                link: "https://aave.com/",
                imgSrc: "./images/logo/aave.png",
                imgAlt: "logo Aave",
                imgTitle: "Aave",
                text: "Aave",
            },

        ],
    },
];

export default skillsData;