export const cryptoPriceCron = `0,30 * * * * node /var/www/dapp/script/crypto-price.js >> /var/www/dapp/script/log/crypto-price.log 2>&1`;

export const cryptoPriceJS = `const mongoose = require('mongoose')

const red = '\x1b[31m'
const blue = '\x1b[34m'
const green = '\x1b[32m'
const reset = '\x1b[0m'

const sleep = ms => new Promise(r => setTimeout(r, ms));

const cryptoPriceSchema = new mongoose.Schema({
    id: String,
    price_usd: Number,
    price_eur: Number
}, { collection: 'crypto_price' });

const CryptoPrice = mongoose.model('CryptoPrice', cryptoPriceSchema);

const connectDB = async() => {
    try {
        await mongoose.connect("mongodb+srv://mydb.mongodb.net/Crypto?retryWrites=true&w=majority", { useNewUrlParser: true, useUnifiedTopology: true });
        console.log('DB connected')
    } catch (err) {
        console.log(err)
    }
}

async function getPrice() {
    const crypto = ["bitcoin", "ethereum", "matic-network", "chainlink", "monero", "for-loot-and-glory", "must", "elrond-erd-2", "crypto-com-chain", "tezos", 'cosmos'];

    for (let i = 0; i < crypto.length; i++) {
        try {
            let response = await fetch(
                \`https://api.coingecko.com/api/v3/simple/price?ids=\${crypto[i]}&vs_currencies=usd,eur\`
            );
            let data = await response.json();

            await CryptoPrice.updateOne({ id: crypto[i] }, {
                $set: {
                    price_usd: data[crypto[i]].usd,
                    price_eur: data[crypto[i]].eur
                }
            }, { upsert: true });

            console.log(\`\${green}crypto \${crypto[i]} successfully saved in USD and EUR\${reset}\`);
            await sleep(30000); // Attendre 30 secondes entre chaque appel
        } catch (e) {
            console.log(\`\${red}\${e}\${reset}\`);
        }
    }
}


const date = new Date();
const day = String(date.getDate()).padStart(2, '0');
const month = String(date.getMonth() + 1).padStart(2, '0');
const year = date.getFullYear();
const hours = String(date.getHours()).padStart(2, '0');
const minutes = String(date.getMinutes()).padStart(2, '0');
const seconds = String(date.getSeconds()).padStart(2, '0');

console.log(\`\${blue}\${day}/\${month}/\${year} \${hours}:\${minutes}:\${seconds}\${reset}\`);

(async() => {
    await connectDB();
    await getPrice().catch(console.error);
    process.exit();
})();`;

export const logFile = `^[[34m25/06/2023 16:30:07^[[0m
^[[31mTypeError: Cannot read properties of undefined (reading 'usd')^[[0m
^[[31mTypeError: Cannot read properties of undefined (reading 'usd')^[[0m
^[[31mTypeError: Cannot read properties of undefined (reading 'usd')^[[0m
^[[31mTypeError: Cannot read properties of undefined (reading 'usd')^[[0m
^[[31mTypeError: Cannot read properties of undefined (reading 'usd')^[[0m
^[[34m25/06/2023 17:00:03^[[0m
30594
^[[32mcrypto bitcoin successfully saved^[[0m
1893.79
^[[32mcrypto ethereum successfully saved^[[0m
0.670255
^[[32mcrypto matic-network successfully saved^[[0m
6.22
^[[32mcrypto chainlink successfully saved^[[0m
160.39
^[[32mcrypto monero successfully saved^[[0m`;

export const btcHistBash = `# /bin/sh
while true
do
echo Starting dapp script
node btc-call-api.js
echo Restarting dapp script in 5 Seconds...
sleep 5
done`;

export const btcHistJS = `const mongoose = require('mongoose');

const BtcDataSchema = new mongoose.Schema({
    date: Date,
    price: Number
}, { collection: 'btc-history' });

const BtcHist = mongoose.model('btc-history', BtcDataSchema);

const connectDB = async() => {
    try {
        await mongoose.connect("mongodb+srv://mydb.mongodb.net/Crypto?retryWrites=true&w=majority", { useNewUrlParser: true, useUnifiedTopology: true });
        console.log('DB connected')
    } catch (err) {
        console.log(err)
    }
}

async function fetchBtc() {

    console.log('running btc call daily')

    const response = await fetch(
        "https://api.coingecko.com/api/v3/coins/bitcoin/market_chart?vs_currency=usd&days=0&interval=daily"
    );
    const data = await response.json();

    const priceRecords = data.prices.map(([timestamp, price]) => {
        // Convertir le timestamp en date
        const date = new Date(timestamp);
        const formattedDate = \`\${date.getFullYear()}-\${(date.getMonth() + 1).toString().padStart(2, '0')}-\${date.getDate().toString().padStart(2, '0')}\`;


        return { date: formattedDate, price: Number(price).toFixed(2) };
    });

    priceRecords.sort((a, b) => new Date(a.date) - new Date(b.date));

    await BtcHist.insertMany(priceRecords);
    console.log("BTC Price data saved");

}

setInterval(async function() {
    let date = new Date();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();
    let now = hour + ":" + minute + ":" + second;

    let update = \`1:0:0\`

    if (now === update) {
        await connectDB();
        fetchBtc();
    }
}, 1000)`;

export const fearHistBash = `# /bin/sh
while true
do
echo Starting dapp script
node fear-call-api.js
echo Restarting dapp script in 5 Seconds...
sleep 5
done`;

export const fearHistJS = `const mongoose = require('mongoose')

const fearGreedSchema = new mongoose.Schema({
    value: String,
    value_classification: String,
    timestamp: Date,
}, { collection: 'fear-and-greed' });

const fearAndGreed = mongoose.model('fear-and-greed', fearGreedSchema);

const connectDB = async() => {
    try {
        await mongoose.connect("mongodb+srv://mydb.mongodb.net/Crypto?retryWrites=true&w=majority", { useNewUrlParser: true, useUnifiedTopology: true });
        console.log('DB connected')
    } catch (err) {
        console.log(err)
    }
}

const fetchFear = async() => {
    console.log('running fear call daily');

    try {
        const response = await fetch(
            "https://api.alternative.me/fng/?limit=1&format=json&date_format=world"
        );
        const { data } = await response.json();
        console.log(data);

        const formattedData = data.map(item => {

            const parts = item.timestamp.split('-');
            const formattedDate = \`\${parts[2]}-\${parts[1]}-\${parts[0]}\`;

            return {
                value: item.value,
                value_classification: item.value_classification,
                timestamp: new Date(formattedDate)
            };
        });

        await fearAndGreed.insertMany(formattedData);
        console.log("Fear and Greed index data saved to MongoDB");
    } catch (err) {
        console.error(err);
    }
}

setInterval(async function() {
    let date = new Date();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();
    let now = hour + ":" + minute + ":" + second;

    let update = \`1:0:0\`

    if (now === update) {
        await connectDB();
        await fetchFear().catch(console.error);
    }
}, 1000)`;

export const cryptoPriceFront = `export const fetchPrice = async(token) => {
    let nPrice = 0
    let date = new Date().getTime

    try {

        const response = await fetch(\`https://api.justokaou.xyz/api/price?id=\${token}&currency=usd\`);
        const data = await response.json();
        const price = data['usd'];

        if (token === "for-loot-and-glory" || token === "crypto-com-chain") {
            nPrice = Number(price).toFixed(3)
        } else {
            nPrice = price
        }

        return nPrice;

    } catch (error) {
        console.log(error);
        return nPrice = 0;
    }
};

export function countDecimals(number) {
    if (Math.floor(number) === number) {
        return 0;
    } else {
        return number.toString().split(".")[1].length || 0;
    }
}

export const sleep = ms => new Promise(r => setTimeout(r, ms));`;
