import { Box, Divider, Text, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TabsFunction } from "../../components/page-content/TabsFunction";
import { Link } from "react-router-dom";
import React from "react";

export const DefinitionPage = () => {
  const { term } = useParams();
  const [blockchainData, setBlockchainData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://api.justokaou.xyz/api/defs", {
          headers: {
            Authorization: process.env.REACT_APP_API_TOKEN,
          },
        });
        const data = await response.json();
        setBlockchainData(data);
      } catch (error) {
        console.error("Error fetching blockchain data:", error);
      }
    };

    fetchData();
  }, []);

  const keywordToRoute = {
    blockchain: "/glossary/blockchain",
    blockchains: "/glossary/blockchain",
    Blockchain: "/glossary/blockchain",
    "chaîne de blocs": "/glossary/blockchain",
    Bitcoin: "/glossary/bitcoin",
    Bitcoins: "/glossary/bitcoin",
    bitcoin: "/glossary/bitcoin",
    Consensus: "/glossary/consensus",
    PoW: "/glossary/PoW",
    "proof of work (PoW)": "/glossary/PoW",
    "Proof of Work (PoW)": "/glossary/PoW",
    "preuve de travail (PoW)": "/glossary/PoW",
    "Preuve de Travail (PoW)": "/glossary/PoW",
    PoS: "/glossary/PoS",
    "Proof of Stake": "/glossary/PoS",
    "proof of stake (PoS)": "/glossary/PoS",
    "preuve d'enjeu (PoS)": "/glossary/PoS",
    "Preuve d'Enjeu (PoS)": "/glossary/PoS",
    "Proof of Stake (PoS)": "/glossary/PoS",
    "Satoshi Nakamoto": "/glossary/satoshi-nakamoto",
    nodes: "/glossary/nodes",
    noeuds: "/glossary/nodes",
    "network participants": "/glossary/nodes",
    "Les autres participants du réseau": "/glossary/nodes",
    "smart contracts": "/glossary/smart-contract",
    "contrats intelligents": "/glossary/smart-contract",
    "decentralized applications": "/glossary/dapp",
    "applications décentralisées": "/glossary/dapp",
    "decentralized applications (DApps)": "/glossary/dapp",
    "DApp (Decentralized Application)": "/glossary/dapp",
    "applications décentralisées (DApps)": "/glossary/dapp",
    "échanges décentralisés (DEX)": "/glossary/dex",
    "decentralized exchanges (DEX)": "/glossary/dex",
    "Decentralized Exchanges (DEX)": "/glossary/dex",
    "places de marché (DEX)": "/glossary/dex",
    "decentralized governance (DAO)": "/glossary/dao",
    "gouvernance décentralisée (DAO)": "/glossary/dao",
    mining: "/glossary/mining",
    minage: "/glossary/mining",
    DAO: "/glossary/dao",
    DAOs: "/glossary/dao",
    Solidity: "/glossary/solidity",
  };

  function parseContent(content) {
    const regex = /\[([^\]]+)\]/g;
    let result = [];
    let lastIndex = 0;

    content.replace(regex, (match, keyword, index) => {
      const route = keywordToRoute[keyword];

      if (route) {
        result.push(content.substring(lastIndex, index));

        result.push(
          <Link className="glossaryButton" to={route} key={index}>
            {keyword}
          </Link>
        );

        lastIndex = index + match.length;
      } else {
        result.push(match);
      }

      return match;
    });

    if (lastIndex < content.length) {
      result.push(content.substring(lastIndex));
    }

    const jsxContent = result.flatMap((item, index) => {
      if (typeof item === "string") {
        const lines = item.split("\n");
        return lines.map((line, lineIndex) => (
          <React.Fragment key={`${index}-${lineIndex}`}>
            {line}
            {lineIndex < lines.length - 1 && <br />}
            {lineIndex < lines.length - 1 && <br />}
          </React.Fragment>
        ));
      } else {
        return item;
      }
    });

    return jsxContent;
  }

  let id;
  let title;
  let fr;
  let en;
  let logo;

  if (blockchainData) {
    for (let i = 0; i < blockchainData.length; i++) {
      if (term === blockchainData[i].id) {
        title = blockchainData[i].title;
        id = blockchainData[i].id;
        logo = blockchainData[i].img;

        const formatedFr = parseContent(blockchainData[i].fr);

        const formatedEn = parseContent(blockchainData[i].en);

        fr = (
          <Flex h="200px" mt="12px">
            <Text>{formatedFr}</Text>
            {/* <Divider orientation="vertical" bg='white' ml='36px' /> */}
          </Flex>
        );

        en = (
          <Flex h="200px" mt="12px">
            <Text>{formatedEn}</Text>
            {/* <Divider orientation="vertical" bg='white' ml='36px' /> */}
          </Flex>
        );
      }
    }
  }

  const tabs = [
    { label: "En", content: en },
    { label: "Fr", content: fr },
  ];

  return (
    <Box w={["375px", null, "650px", "750px", "1000px", "1250px"]}>
      <Box fontSize="24px">{title}</Box>
      <Divider />

      <Box mt="16px">
        <TabsFunction
          width="125px"
          height="30px"
          variant="unstyled"
          tabs={tabs}
        />
      </Box>
    </Box>
  );
};
