import { Box, Stack, Center, Text, Divider, SimpleGrid, Badge } from "@chakra-ui/react";
import { AccordionApi } from "../../../components/accordion/AccordionApi";

export const JsBackendApi = () => {
    return (
        <Box w={['375px', null, '650px', '750px', '1000px', '1250px']}>

            <Stack>
                <Center>
                    <Text className="font-2" fontSize='24px'>Web API</Text> 
                </Center> 
                <Divider />
            </Stack>

            <Stack>
                <Center flex="1" flexDirection='column'>
                    Libraries :

                    <SimpleGrid columns={[2, null, 6]} mt='6px' gap={2}>
                        <Badge colorScheme="green" variant='solid'>express.js</Badge>
                        <Badge colorScheme="green" variant='solid'>express rate limit</Badge>
                        <Badge colorScheme="green" variant='solid'>json web token</Badge>
                        <Badge colorScheme="green" variant='solid'>mongoose</Badge>
                        <Badge colorScheme="green" variant='solid'>viem</Badge>
                        <Badge colorScheme="green" variant='solid'>dotenv</Badge>
                    </SimpleGrid>
                </Center>

                <Center>
                    <Divider w='950px' />
                </Center>
            </Stack>

            <Stack mt='16px'>
                <Center>
                    <Text> Project presentation: </Text> 
                </Center>

                <Text textAlign='center'>
                    I undertook this project because, while aiming to perform statistics with a historical dataset, the data storage issue became apparent. 
                    As a result, I sought a simple and accessible database solution, which led me to MongoDB. 
                    Subsequently, it was crucial to access this data for display, and this is where the backend became the only viable choice. 
                    To achieve this, I have scripts that periodically write to my database. My backend then queries the database. 
                    In my API, I have implemented an express rate limit of 60 requests per minute. 
                    Additionally, I have implemented an authorization token for most endpoints, except for two that I wanted to remain public.
                </Text>
            </Stack>

            <Stack mt='12px'>
                <Center>
                    <Text>You can try my different endpoints below</Text>
                </Center>

                <Center mt='16px'>
                    <AccordionApi />
                </Center>
            </Stack>
        </Box>
    )
}