import { Box, Stack, Divider, Text, Center, Button, useColorModeValue, Flex, VisuallyHidden } from "@chakra-ui/react";
import { Skills } from "../components/page-content/Skills/Skills";
import React, { useRef } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { ScrollToTopButton } from "../components/button/ScrollToTopButton";
import Particles1 from "../components/animation/Particles1";

export const Home = ({ menuWidth, headerHeight }) => {
    const skillsSectionRef = useRef(null);
    const presentationSectionRef = useRef(null);
    const screenHeight = window.innerHeight + headerHeight
    const screenWidth = window.innerWidth
    const backgroundColor = useColorModeValue('rgb(255, 255, 255)', "#1A202C");

    const particlesInit = async (main) => {
        console.log(main);
    
        // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(main);
      };

    const scrollToSkills = () => {
        if (skillsSectionRef.current) {
            let offset;

            if (screenWidth <= 432) {
                offset = -90;
            } else {
                offset = 0;
            }

            const targetPosition = skillsSectionRef.current.getBoundingClientRect().top + window.scrollY + offset;
            window.scrollTo({
                top: targetPosition,
                behavior: "smooth",
            });
        }
    };
    
    

      const scrollToPresentation = () => {
        if (presentationSectionRef.current) {
            presentationSectionRef.current.scrollIntoView({ behavior: "smooth" });
        }
      };

    return(
        <Box overflowY='auto' w={['375px', null, '650px', '750px', '1000px', '1250px']}> 
            <Box>
                <Box position="absolute" top={headerHeight} left={menuWidth} height={`${screenHeight}px`} right='0' zIndex={0}>
                    <Particles id="tsparticles" init={particlesInit} options={Particles1} />
                </Box>

                <Stack zIndex={2} height="100vh" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <Center>
                        <Text color='white' zIndex={2} className="font-2" fontSize='48px'>Welcome to my Portfolio</Text>
                    </Center>
                    <Divider />

                    <Flex mt='12px' gap={2}>
                    <Button zIndex={2} onClick={scrollToPresentation}>
                            About me

                            <ChevronDownIcon ml='4px' fontSize='20px' />
                        </Button>

                        <Button minW='125px' zIndex={2} onClick={scrollToSkills}>
                            Go to skills

                            <ChevronDownIcon ml='4px' fontSize='20px' />
                        </Button>
                    </Flex>
                </Stack>
                
            </Box>

            <Center 
                ref={presentationSectionRef}
                position='absolute'
                top={`${screenHeight}px`}
                left={menuWidth}
                right={menuWidth}
                color='white' 
                h={screenHeight}
                w={`calc(100% - ${menuWidth}px)`}
            >
                <Text zIndex={2} textAlign='center' mt='8px' w={['375px', null, '650px', '750px', '1000px', '1250px']}>
                    <Text fontSize='24px' fontWeight='bold' mb='32px'>About me :</Text>
                    Let me introduce myself, my name is Alexis and I'm 22 years old. 
                    I completed a 2-year training program in computer science specialized in systems and networks at a training center in France, and I graduated in June 2022.
                    <br/><br/>
                    Afterward, I resumed programming, which I had previously set aside, and I intensified my activities starting from November 2022. 
                    On this website that I personally developed using JavaScript with React.js, 
                    I showcase my various personal programming projects as well as the two personal servers that I own and administer. 
                    The goal of this website is to continue evolving over time, and it will be regularly updated with new developments or project creations.
                    <br/><br/>
                    I am passionate about blockchain, cryptocurrency, and economics. 
                    I also have a strong interest in computer science, security, and privacy. In the near future, 
                    I plan to acquire skills in smart contract development using the Solidity programming language.
                    <br/><br/>
                    If you need anything, please don't hesitate to contact me through the contact page.
                </Text>
            </Center>

            <Center
                ref={skillsSectionRef}
                position="absolute"
                zIndex={2}
                left={menuWidth}
                right={menuWidth}
                minH={screenWidth < 1024 ? `${screenHeight +68}px` : `${screenHeight}px`}
                top={`${screenHeight*2}px`}
                backgroundColor={backgroundColor}
                py={4}
                w={`calc(100% - ${menuWidth}px)`}
            >
                <Skills />
            </Center>

            <ScrollToTopButton />
        
        </Box>
    )
}