export const codeMonitoring = `import os
import subprocess
from email.message import EmailMessage
import ssl
import smtplib
from dotenv import load_dotenv


load_dotenv()


class Colors:
    RED = '\\033[91m'
    GREEN = '\\033[92m'
    RESET = '\\033[0m'


user = os.getenv('USER')
recipients = os.getenv("recipients")
password = os.getenv('password')

ip_1 = os.getenv('IP1')
ip_2 = os.getenv('IP2')
server_1 = os.getenv('NAME1')
server_2 = os.getenv('NAME2')
server_3 = os.getenv('NAME3')


def send_email(subject, body):
    sender = user

    # create message
    msg = EmailMessage()
    msg['From'] = sender
    msg['To'] = recipients
    msg['Subject'] = subject
    msg.set_content(body)
    context = ssl.create_default_context()

    # Send email
    with smtplib.SMTP_SSL('smtp.gmail.com', 465, context=context) as smtp:
        smtp.login(user, password)
        smtp.sendmail(user, recipients, msg.as_string())


def check_server(server_name, ip_address):
    # Check ping
    print("\\n----------PING----------")
    ping_cmd = ['ping', '-c', '1', ip_address]
    ping_result = subprocess.run(ping_cmd, capture_output=True, text=True)
    if ping_result.returncode != 0:
        error_message = f"Le serveur {server_name} est inaccessible. Ping Failed.\\n\\n"
        error_message += f"Résultat du ping :\\n{ping_result}"
        send_email('Serveur inaccessible', error_message)
        print(Colors.RED, ping_result, Colors.RESET)
    else:
        print(Colors.GREEN, ping_result, Colors.RESET)

    # Check DNS
    print("\\n----------DNS----------")
    dns_cmd = ['nslookup', server_name]
    dns_result = subprocess.run(dns_cmd, capture_output=True, text=True)
    if dns_result.returncode != 0:
        error_message = f"Le serveur {server_name} rencontre un problème de résolution DNS. nslookup failed.\\n\\n"
        error_message += f"Résultat de nslookup :\\n{dns_result}"
        send_email('Problème DNS', error_message)
        print(Colors.RED, dns_result, Colors.RESET)
    else:
        print(Colors.GREEN, dns_result, Colors.RESET)

    # Check HTTPS connection
    print("\\n----------HTTPS----------")
    curl_cmd = ['curl', '-I', f'https://{server_name}:443']
    curl_result = subprocess.run(curl_cmd, capture_output=True, text=True)
    print(curl_result)
    if curl_result.returncode != 0:
        error_message = f"La connexion HTTPS au serveur {server_name} a échoué. curl failed.\\n\\n"
        error_message += f"Résultat de curl :\\n{curl_result}"
        send_email('Connexion HTTPS échouée', error_message)
        print(Colors.RED, curl_result, Colors.RESET)
        print(test)
    else:
        print(Colors.GREEN, curl_result, Colors.RESET)


check_server(server_1, ip_1)
check_server(server_2, ip_2)
check_server(server_3, ip_2)`

export const codeBackup = `import paramiko
import os
from dotenv import load_dotenv
from tqdm import tqdm
from datetime import date
from functools import partial

load_dotenv()
paramiko.util.log_to_file("paramiko.log")
today = date.today()
date_format = today.strftime("%d-%m-%Y")
print(date_format)


def progress_callback(transferred, total, progress):
    progress.update(transferred - progress.n)


def download_from_server(host, port, username, password):
    transport = paramiko.Transport((host, port))
    transport.connect(None, username, password)
    sftp = paramiko.SFTPClient.from_transport(transport)

    if host != os.getenv('host2'):
        remote_filepath = f"backup-system_{date_format}.tar.gz"
        local_filepath = f"/root/py-get-backup/backup/rpi/{remote_filepath}"
    elif host == os.getenv('host2'):
        remote_filepath = f"backup_{date_format}.tar.gz"
        local_filepath = f"/root/py-get-backup/backup/vps/{remote_filepath}"

    print(f"Download {remote_filepath} to {local_filepath}")

    remote_file_size = sftp.stat(remote_filepath).st_size
    bar_format = 'Downloading: {percentage:3.0f}%|{bar}| {n_fmt}/{total_fmt} [{elapsed}, {rate_fmt}{postfix}]'

    with tqdm(total=remote_file_size, unit='B', unit_scale=True, ncols=80, bar_format=bar_format) as progress:
        progress.set_description('Downloading')
        sftp.get(remote_filepath, local_filepath, callback=partial(progress_callback, progress=progress))

    if sftp:
        sftp.close()
    if transport:
        transport.close()


host1, port1 = os.getenv('host'), 22222
username1, password1 = os.getenv('user'), os.getenv('password')
download_from_server(host1, port1, username1, password1)

host2, port2 = os.getenv('host2'), 22
username2, password2 = os.getenv('user2'), os.getenv('password2')
download_from_server(host2, port2, username2, password2)`